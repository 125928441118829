const GEO_WEB_CACHE_PATH = '/gwc/service/tms/1.0.0';

const getSourceOptions = (sourceOptions) => {
  const { serverType, url = '' } = sourceOptions || {};
  const { GEOSERVER_URL, GEO_WEB_CACHE_URL } = window;

  const isGeoserverProxy = GEOSERVER_URL && serverType === 'geoserver';

  if (!isGeoserverProxy) return sourceOptions;

  const isGeoWebCache = url.includes(GEO_WEB_CACHE_PATH);

  const newURL = isGeoWebCache ? GEO_WEB_CACHE_URL + url.split(GEO_WEB_CACHE_PATH)[1] : GEOSERVER_URL;

  return { ...sourceOptions, url: newURL };
};

export default getSourceOptions;
