/**
 * A dialog component to display the contact info.
 */

import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import { Trigger } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';
import StackedDialog from '@geomagic/nam-react-core/components/StackedDialog';

import { PRIMARY_TRIGGER_PROPS } from '@consts';

const AboutDialog = (props) => {
  const { isOpen, onClose, systemInformation } = props;
  const { serviceMail, servicePhoneNumber, serviceTimes, version } = systemInformation;
  const appversion = window.VERSION || '';

  const tableCellSx = () => ({
    borderBottom: 'none',
  });

  const ContentComponent = (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} sm={12}>
        <Table style={{ width: '100%' }}>
          <TableBody>
            {!!appversion && (
              <TableRow>
                <TableCell sx={{ ...tableCellSx() }}>{`${i18n.t('label.app')}: `}</TableCell>
                <TableCell sx={{ ...tableCellSx() }} align="right">
                  {appversion}
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell sx={{ ...tableCellSx() }}>{`${i18n.t('label.versionBackend')}: `}</TableCell>
              <TableCell sx={{ ...tableCellSx() }} align="right">
                {version}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ ...tableCellSx() }}>{`${i18n.t('label.serviceMail')}: `}</TableCell>
              <TableCell sx={{ ...tableCellSx() }} align="right">
                {serviceMail}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ ...tableCellSx() }}>{`${i18n.t('label.serviceHotline')}: `}</TableCell>
              <TableCell sx={{ ...tableCellSx() }} align="right">
                {servicePhoneNumber}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ ...tableCellSx() }}>{`${i18n.t('label.serviceTimes')}: `}</TableCell>
              <TableCell sx={{ ...tableCellSx() }} align="right">
                {serviceTimes}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );

  const ActionsComponent = (
    <Trigger key="close" {...PRIMARY_TRIGGER_PROPS} onClick={onClose}>
      {i18n.t('button.close')}
    </Trigger>
  );

  return (
    <StackedDialog
      actions={ActionsComponent}
      content={ContentComponent}
      handleClose={onClose}
      isFullscreen={false}
      open={isOpen}
      title={i18n.t('dialog.about.title', { variables: { name: window.TITLE } })}
    />
  );
};

AboutDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  Logo: PropTypes.object,
  systemInformation: PropTypes.object.isRequired,
};

export default AboutDialog;
