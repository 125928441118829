import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';

import { makeStyles } from '@geomagic/core';
import { ContentRoot } from '@geomagic/layout';
import { i18n } from '@geomagic/i18n';

import OfflineMapSections from '../OfflineMap/OfflineMapSections';

const useStyles = makeStyles()(() => {
  return {
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
  };
});

const OfflineCache = (props) => {
  const { adminUnits, className, isMobile, isOnline, mapProps } = props;
  const { vectorTileServerUrl } = mapProps;
  const { classes } = useStyles(props);

  /**
   *  EVENT HANDLER
   */

  return (
    <ContentRoot
      className={classNames(classes.root, className)}
      scrollable
      withPadding={false}
      withCustomScrollbar={!isMobile}
    >
      <ListSubheader>{i18n.t('label.preference.offlineMap.title')}</ListSubheader>
      <Divider />
      <Box sx={{ padding: 2 }}>
        {vectorTileServerUrl ? (
          <>
            <Typography variant="body2">{i18n.t('label.preference.offlineMap.subtitle')}</Typography>
            <OfflineMapSections adminUnits={adminUnits} isOnline={isOnline} mapProps={mapProps} />
          </>
        ) : (
          <Typography variant="body2">{i18n.t('label.preference.offlineMap.notAvailable')}</Typography>
        )}
      </Box>
    </ContentRoot>
  );
};

OfflineCache.propTypes = {
  adminUnits: PropTypes.array.isRequired,
  className: PropTypes.string,
  isOnline: PropTypes.bool,
  isMobile: PropTypes.bool,
  mapProps: PropTypes.object.isRequired,
};

export default OfflineCache;
