import handleFetchError from './handleFetchError';
import checkSyncErrors from './checkSyncErrors';

/**
 * Push all changes from the app to the backend.
 * @param {object} params
 * @param {object} params.client
 * @param {object} params.config
 * @param {object} params.database
 * @param {Array} params.entityClasses
 * @param {object} params.mapProps
 * @param {string} params.userId
 * @returns
 */

const push = async (params) => {
  const { client, config, database, entityClasses, mapProps, userId } = params;
  const syncErrors = [];

  for (let i = 0; i < config.length; i++) {
    const { collectionName, onSync, refetchQuery, selector, transform } = config[i];

    try {
      const collection = database[collectionName];
      const modifiedDocs = await collection.find({ selector: { ...selector, userId } }).exec();

      if (modifiedDocs) {
        for (const doc of modifiedDocs) {
          if (onSync) {
            await onSync({
              client,
              database,
              doc,
              entityClasses,
              mapProps,
              refetchQuery,
              selector,
              syncErrors,
              transform,
            });
          }
        }
      }
    } catch (error) {
      handleFetchError({ error });
    }
  }

  checkSyncErrors(syncErrors);
};

export default push;
