export const ENTITY_SELECTOR_KEY = 'entity.id';
export const ENTITY_UNIQ_KEY = 'id';

export const REMOTE_VERSION = 3;

export const UPDATE_ATTRIBUTES_POINTER = '/attributeValues';
export const UPDATE_CHECKLIST_POINTER = '/checklistItems';
export const UPDATE_DOCUMENTS_POINTER = '/documents';
export const UPDATE_GEOMETRIES_POINTER = '/featureCollections/0/features';
export const UPDATE_JOURNAL_POINTER = '/journal';
