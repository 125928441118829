import handleSyncError from './handleSyncError';
import isIncludingInPatch from './isIncludingInPatch';
import removePatchByPath from './removePatchByPath';

const syncPathAndRemovePatch = async ({ mutation, doc, path, entityReference, syncErrors, updateProps }) => {
  if (isIncludingInPatch(doc, path)) {
    try {
      await mutation({ ...updateProps, entityReference, path });
      await removePatchByPath(doc, path);
    } catch (error) {
      handleSyncError({ entityReference, error, syncErrors });
    }
  }
};

export default syncPathAndRemovePatch;
