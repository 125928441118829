import { getReference } from '@geomagic/geonam';

import getUpdatedDoc from './getUpdatedDoc';
import handleFetchError from './handleFetchError';
import handleSyncError from './handleSyncError';
import createEntity from './updates/createEntity';
import resetDoc from './resetDoc';
import resetDraftDoc from './resetDraftDoc';
import syncPathAndRemovePatch from './syncPathAndRemovePatch';
import updateChecklist from './updates/updateChecklist';
import updateDocuments from './updates/updateDocuments';
import updateEntity from './updates/updateEntity';
import updateGeometries from './updates/updateGeometries';
import updateJournal from './updates/updateJournal';
import {
  UPDATE_ATTRIBUTES_POINTER,
  UPDATE_CHECKLIST_POINTER,
  UPDATE_DOCUMENTS_POINTER,
  UPDATE_GEOMETRIES_POINTER,
  UPDATE_JOURNAL_POINTER,
} from './consts';

/**
 * Synchronizes the changes/json patches to the doc with the backend and remove them separately
 * as there might me connection issues.
 * @param {object} params
 * @param {object} params.client
 * @param {object} params.doc as RxDocument
 * @param {Array} params.entityClasses
 * @param {object} params.mapProps
 * @param {Function} params.refetchQuery
 * @param {Array} params.syncErrors
 * @returns
 */

const getDefaultSync = async (params) => {
  const { client, doc, entityClasses, mapProps, refetchQuery, syncErrors } = params;
  let entityReference = getReference(doc?.entity);

  try {
    const isDraft = !!doc?.draft;

    const updateProps = {
      client,
      doc,
      entityClasses,
      entity: doc?.entity,
      patchedEntity: doc.getPatchedEntity(),
    };

    if (isDraft) {
      const createdEntityReference = await createEntity(updateProps);

      if (createdEntityReference) {
        entityReference = createdEntityReference;
        await resetDraftDoc(doc, entityReference);
      }
    } else {
      await syncPathAndRemovePatch({
        mutation: updateEntity,
        doc,
        path: UPDATE_ATTRIBUTES_POINTER,
        entityReference,
        syncErrors,
        updateProps,
      });
    }

    await syncPathAndRemovePatch({
      mutation: updateGeometries,
      doc,
      path: UPDATE_GEOMETRIES_POINTER,
      entityReference,
      syncErrors,
      updateProps,
    });
    await syncPathAndRemovePatch({
      mutation: updateDocuments,
      doc,
      path: UPDATE_DOCUMENTS_POINTER,
      entityReference,
      syncErrors,
      updateProps,
    });
    await syncPathAndRemovePatch({
      mutation: updateJournal,
      doc,
      path: UPDATE_JOURNAL_POINTER,
      entityReference,
      syncErrors,
      updateProps,
    });
    await syncPathAndRemovePatch({
      mutation: updateChecklist,
      doc,
      path: UPDATE_CHECKLIST_POINTER,
      entityReference,
      syncErrors,
      updateProps,
    });

    const hadErrors = syncErrors.length > 0;

    const updatedEntity = refetchQuery && (await getUpdatedDoc(client, refetchQuery, entityReference, mapProps));

    if (updatedEntity) {
      if (hadErrors) {
        await resetDoc(doc, updatedEntity, doc?.jsonPatch);
      } else {
        await resetDoc(doc, updatedEntity);
      }
    }
  } catch (error) {
    handleFetchError({ error, isDefaultThrow: false });
    handleSyncError({ entityReference, error, syncErrors });
  } finally {
    return entityReference;
  }
};

export default getDefaultSync;
