import { transformExtent } from 'ol/proj';

import addToCache from '../utils/addToCache';
import getEPSGString from '../utils/getEPSGString';
import getVectorTilesExtentUrls from '../utils/getVectorTilesExtentUrls';
import { BASE_EXTENT, BASE_KEY, MAX_BASE_TILE_ZOOM_LEVEL, MIN_BASE_TILE_ZOOM_LEVEL } from '../consts';
import useFetchPbfUrl from './useFetchPbfUrl';

const useOfflineBaseTiles = ({
  maxZoomLevel = MAX_BASE_TILE_ZOOM_LEVEL,
  minZoomLevel = MIN_BASE_TILE_ZOOM_LEVEL,
  mapProps,
  abortSignal = false,
}) => {
  const { srid, vectorTileLayers, vectorTileServerUrl } = mapProps;
  const { epsgCode, extent } = BASE_EXTENT;
  const transformedExtent = transformExtent(extent, epsgCode, getEPSGString(srid || 3857));

  const { getPbfUrl } = useFetchPbfUrl(vectorTileServerUrl);

  const addBaseTiles = async () => {
    const pbfUrl = await getPbfUrl();

    if (pbfUrl) {
      const getExtentVectorUrls = getVectorTilesExtentUrls({
        extent: transformedExtent,
        url: pbfUrl,
        maxZoomLevel,
        minZoomLevel,
      });
      await addToCache(BASE_KEY, getExtentVectorUrls, abortSignal);
    }

    for (let i = 0; i < vectorTileLayers.length; i++) {
      const { sourceOptions } = vectorTileLayers[i];
      const sourceOptionsUrl = sourceOptions?.url;

      if (sourceOptionsUrl) {
        const getExtentVectorLayersUrls = getVectorTilesExtentUrls({
          extent: transformedExtent,
          url: sourceOptionsUrl,
          maxZoomLevel,
          minZoomLevel,
        });
        await addToCache(BASE_KEY, getExtentVectorLayersUrls, abortSignal);
      }
    }
  };

  const deleteBaseTiles = async () => {
    await window.caches.delete(BASE_KEY);
  };

  const hasBaseTiles = async () => {
    return await window.caches.has(BASE_KEY);
  };

  return {
    addBaseTiles,
    deleteBaseTiles,
    hasBaseTiles,
  };
};

export default useOfflineBaseTiles;
