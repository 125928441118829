import { get as getProjection } from 'ol/proj';

export const MAP_SRID = 3857;
export const VIEW_CODE = 'vew.default';
//export const VIEW_CODE = 'nam.vew.map.google';
export const WORLD_CODE = 'map.wld.default';

export const DEFAULT_GEOLOCATION_PROJECTION = 'EPSG:4326';
export const DEFAULT_GEOLOCATION_SETTINGS = {
  projection: getProjection(DEFAULT_GEOLOCATION_PROJECTION),
  tracking: false,
  trackingOptions: {
    enableHighAccuracy: true,
    maximumAge: 0,
    timeout: 5000,
  },
};
export const DEFAULT_UPLOAD_SRID = 25833;
export const DEFAULT_SIDEBAR_WIDTH = 300;
export const DEFAULT_DURATION = 500;

export const DEFAULT_CENTER_OPTIONS = {
  duration: DEFAULT_DURATION,
};

export const DEFAULT_FIT_OPTIONS = {
  duration: DEFAULT_DURATION,
  padding: [50, 50, 50, 50],
};

export const DEFAULT_NAVIGATION_URLS = {
  // eslint-disable-next-line no-template-curly-in-string
  all: 'http://maps.google.com/maps?saddr=&daddr=${latlon}',
  // eslint-disable-next-line no-template-curly-in-string
  android: 'http://maps.google.com/maps?saddr=&daddr=${latlon}',
  // eslint-disable-next-line no-template-curly-in-string
  ios: 'http://maps.apple.com/?daddr=${latlon}',
  // eslint-disable-next-line no-template-curly-in-string
  windowsPhone: 'ms-drive-to:?destination.latitude=${lat}&destination.longitude=${lon}&destination.name=${name}',
};

export const CALIBRATION_WARNING_KEY = 'Map.calibrationWarning';
export const ORIENTATION_GRANTED_KEY = 'Map.orientationGranted';
export const POSITION_SETTINGS_KEY = 'Map.positionSettings';

export const DEFAULT_SYSTEM_ZINDEX = 100000;

const VECTOR_COLOR = '#FF087F';
const VECTOR_FILL = 'rgba(255, 255, 255, 0.4)';
const fill = { color: VECTOR_FILL };
const stroke = { color: VECTOR_COLOR, width: 2.5 };

export const DEFAULT_VECTOR_STYLE = {
  imageCircle: {
    radius: 5,
    fill,
    stroke,
  },
  fill,
  stroke,
};
