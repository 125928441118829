import addToCache from '../utils/addToCache';
import { BASE_KEY, MAX_TILE_ZOOM_LEVEL, MIN_TILE_ZOOM_LEVEL } from '../consts';
import getVectorTilesUrlsForCollections from '../utils/getVectorTilesUrlsForCollections';
import useFetchPbfUrl from './useFetchPbfUrl';
import getSourceOptions from '../../../Map/utils/getSourceOptions';

const useOfflineTiles = (params) => {
  const { mapProps, adminUnit } = params;
  const { srid, vectorTileLayers = [], vectorTileServerUrl } = mapProps;

  const collections = adminUnit?.featureCollections;
  const offlineMapId = `${BASE_KEY}_${adminUnit.id}`;

  const { getPbfUrl } = useFetchPbfUrl(vectorTileServerUrl);

  const addOfflineTiles = async (abortController) => {
    const abortSignal = abortController?.signal;
    // get style and data json and pushes it to the Cache Storage and retrieve pbf url
    const tileUrl = await getPbfUrl();

    if (tileUrl) {
      // Get Feature Tiles
      const urls = getVectorTilesUrlsForCollections({
        collections,
        minZoomLevel: MIN_TILE_ZOOM_LEVEL,
        maxZoomLevel: MAX_TILE_ZOOM_LEVEL,
        url: tileUrl,
        destinationSrid: srid,
      });

      await addToCache(offlineMapId, urls, abortSignal);
    }

    // For all Layers
    for (let i = 0; i < vectorTileLayers.length; i++) {
      const { id, sourceOptions } = vectorTileLayers[i];
      const cacheId = `${offlineMapId}_${id}`;
      const url = getSourceOptions(sourceOptions).url;

      if (url) {
        const urls = getVectorTilesUrlsForCollections({
          collections,
          minZoomLevel: MIN_TILE_ZOOM_LEVEL,
          maxZoomLevel: MAX_TILE_ZOOM_LEVEL,
          url,
          destinationSrid: srid,
        });

        await addToCache(cacheId, urls, abortSignal);
      }
    }
  };

  const deleteOfflineTiles = async () => {
    await window.caches.delete(offlineMapId);

    vectorTileLayers.forEach(async (vectorTileLayer) => {
      const { id } = vectorTileLayer;
      const cacheId = `${offlineMapId}_${id}`;
      await window.caches.delete(cacheId);
    });
  };

  const hasOfflineTiles = async () => {
    return await window.caches.has(offlineMapId);
  };

  return {
    addOfflineTiles,
    deleteOfflineTiles,
    hasOfflineTiles,
  };
};

export default useOfflineTiles;
