import MVT from 'ol/format/MVT';
import { transformExtent } from 'ol/proj';
import VectorTileSource from 'ol/source/VectorTile';
import getEPSGString from './getEPSGString';
import GeoJSON from 'ol/format/GeoJSON';

const getVectorTilesUrlsForCollections = ({ collections = [], destinationSrid, maxZoomLevel, minZoomLevel, url }) => {
  const tileUrls = [];
  const tileSource = new VectorTileSource({ format: new MVT(), url });
  const tileGrid = tileSource.getTileGrid();
  const tileUrlFunction = tileSource.getTileUrlFunction();

  for (let i = minZoomLevel; i <= maxZoomLevel; i++) {
    collections.forEach((collection) => {
      const { features } = collection;

      const sourceSrid = collection.srid;
      const bbox = collection.bbox;
      const dataProjection = getEPSGString(sourceSrid);
      const featureProjection = getEPSGString(destinationSrid);
      const geoJSON = new GeoJSON({
        dataProjection,
        featureProjection,
      });

      const geoJSONFeatures = features.map((feature) => geoJSON.readFeature(feature));

      const transformedExtent = transformExtent(bbox, dataProjection, featureProjection);

      tileGrid.forEachTileCoord(transformedExtent, i, (tileCoords) => {
        const extent = tileGrid.getTileCoordExtent(tileCoords);
        const isIntersected = geoJSONFeatures.some((feature) => feature.getGeometry().intersectsExtent(extent));

        if (isIntersected) {
          const tileUrl = tileUrlFunction(tileCoords, 1);
          tileUrls.push(tileUrl);
        }
      });
    });
  }

  return tileUrls;
};

export default getVectorTilesUrlsForCollections;
