import React from 'react';
import { i18n } from '@geomagic/i18n';

import GeneralPreferences from './modals/GeneralPreferences';
import MapPreferences from './modals/MapPreferences';
import OfflineCache from './modals/OfflineCache';

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';

const getMenuItems = () => {
  return [
    {
      id: 'general',
      label: i18n.t('label.preference.general'),
      Component: GeneralPreferences,
      Icon: <SettingsOutlinedIcon />,
    },
    {
      id: 'map',
      label: i18n.t('label.preference.map'),
      Component: MapPreferences,
      Icon: <MapOutlinedIcon />,
    },
    {
      id: 'offline',
      label: i18n.t('label.preference.offline'),
      Component: OfflineCache,
      Icon: <CachedOutlinedIcon />,
    },
  ];
};

export default getMenuItems;
