import MVT from 'ol/format/MVT';
import VectorTileSource from 'ol/source/VectorTile';

/**
 * Retrieve all vector tiles from a specific point or along a line
 * @param {object} params
 * @param {number} params.extent - extent of the Tile
 * @param {number} params.maxZoomLevel - Max zoom level
 * @param {number} params.minZoomLevel - Min zoom level
 * @param {string} params.url - Url of the tile server (pbf)
 * @returns {Array.<string>}
 */
const getVectorTilesExtentUrls = ({ extent, maxZoomLevel, minZoomLevel, url }) => {
  const tileUrls = [];
  const tileSource = new VectorTileSource({ format: new MVT(), url });
  const tileGrid = tileSource.getTileGrid();
  const tileUrlFunction = tileSource.getTileUrlFunction();

  for (let i = minZoomLevel; i <= maxZoomLevel; i++) {
    tileGrid.forEachTileCoord(extent, i, (tileCoords) => {
      const tileUrl = tileUrlFunction(tileCoords, 1);
      tileUrls.push(tileUrl);
    });
  }

  return tileUrls;
};

export default getVectorTilesExtentUrls;
