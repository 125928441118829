import { useQuery } from '@apollo/client';
import { getEntityClass, getReference } from '@geomagic/geonam';

import { VIEW_CODE } from '@components/Map/consts';
import QueryEntityClasses from '@graphql/queries/QueryEntityClasses';
import QuerySSOSettings from '@graphql/queries/QuerySSOSettings';
import QuerySystemInformation from '@graphql/queries/QuerySystemInformation';
import QueryWorld from '@graphql/queries/QueryWorld';
import QueryAdmitUnits from '@graphql/queries/QueryAdmitUnits';
import {
  CLASSNAME_ASSIGNMENT,
  CLASSNAME_ADMINUNIT,
  CLASSNAME_DISPATCH,
  CLASSNAME_DOCUMENT,
  CLASSNAME_FOLDER,
  CLASSNAME_FUNCLOC,
  EMPTY_FILTER_CONFIG,
  ENTITY_TYPE_OFFLINE_AREA_CODE,
} from '@graphql/consts';

const DEFAULT_ENTITY_CLASS_NAMES = [
  CLASSNAME_ASSIGNMENT,
  CLASSNAME_DISPATCH,
  CLASSNAME_FUNCLOC,
  CLASSNAME_DOCUMENT,
  CLASSNAME_FOLDER,
  CLASSNAME_ADMINUNIT,
];

const useGeneralQueries = ({ appProps, isOnline, user }) => {
  /**
   *  QUERY CONFIG
   */

  const queryConfigEntityClasses = {
    skip: isOnline && !user,
    variables: {
      classNames: DEFAULT_ENTITY_CLASS_NAMES,
    },
  };

  const queryConfigSSOSettings = {
    fetchPolicy: 'network-only',
  };

  const queryConfigSystemInformation = {
    skip: isOnline && !user,
  };

  const queryConfigWorld = {
    skip: isOnline && !user,
    variables: {
      viewCodes: [appProps?.viewCode || VIEW_CODE],
    },
  };

  /**
   *  QUERIES
   */

  const { data: dataEntityClasses, loading: loadingEntityClasses } = useQuery(
    QueryEntityClasses,
    queryConfigEntityClasses
  );

  const entityClasses = dataEntityClasses?.entityClasses;

  const { data: dataSSOSettings, loading: loadingSSOSettings } = useQuery(QuerySSOSettings, queryConfigSSOSettings);

  const { data: dataSystemInformation, loading: loadingSystemInformation } = useQuery(
    QuerySystemInformation,
    queryConfigSystemInformation
  );

  const { data: dataWorld, loading: loadingWorld } = useQuery(QueryWorld, queryConfigWorld);

  const adminUnitClass = entityClasses ? getEntityClass(entityClasses, CLASSNAME_ADMINUNIT) : null;
  const offlineAreaEntityType = adminUnitClass?.entityTypes?.find(
    (entityType) => entityType.code === ENTITY_TYPE_OFFLINE_AREA_CODE
  );

  const queryAdminUnits = {
    skip: (isOnline && !user) || !offlineAreaEntityType,
    variables: {
      filter: {
        ...EMPTY_FILTER_CONFIG,
        entityClassNames: [CLASSNAME_ADMINUNIT],
        entityTypes: offlineAreaEntityType ? [getReference(offlineAreaEntityType)] : [],
        sort: { property: 'NAME', ascending: true },
      },
    },
  };

  const { data: adminUnits, loading: loadingAdminUnits } = useQuery(QueryAdmitUnits, queryAdminUnits);

  return {
    entityClasses,
    adminUnits: adminUnits?.entities?.values || [],
    ssoSettings: dataSSOSettings?.systemInformation?.ssoSettings,
    systemInformation: dataSystemInformation?.systemInformation,
    worlds: dataWorld?.worlds,
    loadingAdminUnits,
    loadingEntityClasses,
    loadingSSOSettings,
    loadingSystemInformation,
    loadingWorld,
  };
};

export default useGeneralQueries;
