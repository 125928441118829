import isObject from 'lodash/isObject';

import { useStickySessionState } from '@geomagic/nam-react-core/utils';
import { i18n } from '@geomagic/i18n';

import { BASE_KEY, VECTOR_TILE_PBF_URL } from '../consts';
import fetchUrl from '../utils/fetchUrl';

const useFetchPbfUrl = (baseUrl, key = BASE_KEY) => {
  const [pbfUrl, setPbfUrl] = useStickySessionState(VECTOR_TILE_PBF_URL);

  const handleFetchTileUrl = async () => {
    let baseFetchResult;
    const urlsToCache = [];

    try {
      if (baseUrl) {
        baseFetchResult = await fetchUrl(baseUrl);
      }
    } catch (error) {
      throw new Error(i18n.t('offlineMap.notification.fetchError'));
    }

    if (baseFetchResult) {
      const cache = await window.caches.open(key);

      const { json: baseJson, response: baseResponse } = baseFetchResult;
      urlsToCache.push({ url: baseUrl, response: baseResponse });

      if (isObject(baseJson?.sources)) {
        const sources = baseJson.sources;
        const source = Object.keys(sources)[0];
        const { url: sourceUrl } = sources[source] || {};

        if (sourceUrl) {
          const fetchResult = await fetchUrl(sourceUrl);

          if (fetchResult) {
            const { json: dataJson, response: sourceResponse } = fetchResult;
            const clonedResponse = sourceResponse.clone();

            urlsToCache.push({ url: sourceUrl, response: clonedResponse });
            const cacheToPut = urlsToCache.map(({ url, response }) => cache.put(url, response));

            await Promise.all([...cacheToPut]);

            const pbf = dataJson?.tiles?.[0];
            setPbfUrl(pbf);
          }
        }
      }
    }
  };

  const getPbfUrl = async () => {
    if (pbfUrl) {
      return pbfUrl;
    } else {
      return await handleFetchTileUrl();
    }
  };

  return {
    getPbfUrl,
  };
};

export default useFetchPbfUrl;
