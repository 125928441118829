import gql from 'graphql-tag';

import FragmentFeatureCollections from '../fragments/FragmentFeatureCollections';

const QueryAdmitUnits = gql`
  ${FragmentFeatureCollections}

  query QueryAdmitUnits($filter: FilterInput!, $srid: Int, $worldId: NID) {
    entities(filter: $filter) {
      values {
        displayName
        id
        ...FeatureCollections
      }
      totalValueCount
    }
  }
`;

export default QueryAdmitUnits;
