import fetchUrl from './fetchUrl';

/**
 * Add urls and response to cache storage.
 * @param {string} key - Cache key
 * @param {Array.<string>} urls - Urls to cache
 * @param {boolean} abortSignal - signal to abort fetch
 * @returns {void}
 */
const addToCache = async (key, urls = [], abortSignal = false) => {
  const cache = await window.caches.open(key);

  for (let i = 0; i < urls.length; i++) {
    const url = urls[i];

    const { response } = await fetchUrl(url, abortSignal);

    if (response && response?.status === 200) {
      await cache.put(url, response);
    }
  }
};

export default addToCache;
