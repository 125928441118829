export const BASE_KEY = 'offlineMap';

// Base extent for offline map (currently Germany)
export const BASE_EXTENT = {
  epsgCode: 'EPSG:4326',
  extent: [5.75, 47.19, 15.18, 55.14],
};

export const MIN_BASE_TILE_ZOOM_LEVEL = 0;
export const MAX_BASE_TILE_ZOOM_LEVEL = 6;
export const MIN_TILE_ZOOM_LEVEL = 7;
export const MAX_TILE_ZOOM_LEVEL = 13;

export const VECTOR_TILE_PBF_URL = 'vector.tile.pbf.url';
