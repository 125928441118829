import { i18n } from '@geomagic/i18n';

import { DEFAULT_VECTOR_STYLE } from '../consts';
import getLayerGroups from './getLayerGroups';
import getSourceOptions from './getSourceOptions';

/**
 * Transform layers from GeoNAM backend to geomagic map layers
 * @param {object} params
 * @param {Array.<object>} params.layers - GeoNAM layers
 * @param {object} layerSelectionStore - Localstorage LayerSelectionStore
 * @returns {Array.<object>}
 */

const transformLayers = (view = {}, layerSelectionStore, vectorTileServerUrl = '') => {
  const { baseLayers = [], overlayLayers = [] } = view;
  const transformedLayers = [];
  const { backgroundLayerId, selectedLayers } = layerSelectionStore.getLayerSelection();

  const visibleBackgroundLayerId = backgroundLayerId || baseLayers.find(({ visible }) => visible)?.name;
  if (visibleBackgroundLayerId && visibleBackgroundLayerId !== backgroundLayerId) {
    layerSelectionStore.saveLayerSelection({ backgroundLayerId: visibleBackgroundLayerId });
  }
  const newSelectedLayers = [];

  baseLayers.forEach((baseLayer) => {
    const { name, id, sourceType, sourceOptions, options, filterable, copyright } = baseLayer;
    const type = sourceType === 'XYZ' ? 'OSM' : sourceType; // TODO implement XYZ source in @geomagic/map

    const isVisible = visibleBackgroundLayerId === id;

    const transformedLayer = {
      id: String(id),
      _id: String(id),
      layerPackage: 'default',
      name,
      type,
      isBackground: true,
      values: JSON.stringify(getSourceOptions(sourceOptions)),
      layerValues: JSON.stringify(options),
      isVisible,
      filterable,
      copyright,
    };
    transformedLayers.push(transformedLayer);
  });

  // Offline Map
  if (!!vectorTileServerUrl) {
    transformedLayers.push({
      id: 'offlineMap',
      _id: 'offlineMap',
      type: 'MapboxGL',
      isBackground: true,
      isVisible: true,
      layerPackage: 'default',
      layerValues: '{"opacity":1}',
      name: i18n.t('offlineMap.label'),
      values: `{"url": "${vectorTileServerUrl}"}`,
    });
  }

  overlayLayers.forEach((viewLayer) => {
    const { name, id, visible, sourceType, sourceOptions, options, filterable, grouping, seqNumber, copyright } =
      viewLayer;
    const type = sourceType === 'XYZ' ? 'OSM' : sourceType; // TODO implement XYZ source in @geomagic/map
    const layersGroups = getLayerGroups(grouping, visible);

    if (layersGroups) {
      layersGroups.forEach((item) => {
        const foundIndex = transformedLayers.findIndex((element) => element._id === item._id);
        if (foundIndex === -1) {
          transformedLayers.push(item);
        } else if (!transformedLayers[foundIndex].isVisible && item.isVisible) {
          transformedLayers.splice(foundIndex, 1, item);
        }
      });
    }

    const transformedLayer = {
      id: String(id),
      _id: String(id),
      layerPackage: 'default',
      name,
      type,
      values: JSON.stringify(getSourceOptions(sourceOptions)),
      layerValues: JSON.stringify(options),
      isVisible: visible,
      filterable,
      seqNumber,
      copyright,
      ...(type === 'VectorTile' && {
        sourceType: 'MVT',
        layerValues: JSON.stringify({ ...options, style: DEFAULT_VECTOR_STYLE }),
      }),
      ...(layersGroups && layersGroups.length > 0 && { parent: layersGroups[layersGroups.length - 1]._id }),
    };
    transformedLayers.push(transformedLayer);
  });

  transformedLayers.forEach((transformedLayer) => {
    const { id, isBackground, isVisible } = transformedLayer;
    const selectedLayer = selectedLayers.find((layer) => layer.layerId === id);
    if (!isBackground) {
      if (selectedLayer) {
        let isLayerGroupExcist = false;
        const foundetLayer = newSelectedLayers.find((layer) => layer.layerId === selectedLayer.layerId);
        if (foundetLayer) {
          if (!isLayerGroupExcist) isLayerGroupExcist = true;
          foundetLayer.isVisible = selectedLayer.isVisible;
        }
        if (!isLayerGroupExcist) newSelectedLayers.push(selectedLayer);
      } else {
        newSelectedLayers.push({
          layerId: id,
          isVisible,
        });
      }
    }
  });
  layerSelectionStore.saveLayerSelection({ selectedLayers: newSelectedLayers });

  return transformedLayers;
};

export default transformLayers;
