import React from 'react';
import PropTypes from 'prop-types';

import { useSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import CloseIcon from '@mui/icons-material/Close';

import { i18n } from '@geomagic/i18n';
import { Trigger } from '@geomagic/core';

const CancelButton = (props) => {
  const { onClick } = props;

  return (
    <Trigger
      icon={<CloseIcon />}
      onClick={onClick}
      tooltip={i18n.t('tooltip.cancelChange')}
      color="inherit"
      size="small"
      variant="icon"
    />
  );
};

CancelButton.propTypes = {
  onClick: PropTypes.func,
};

const useLoadingSnackbar = (props = {}) => {
  const { key = '' } = props;
  const snackbarKeyLoading = `_loading_${key}`;
  const snackbarKeyLoadingFinished = `_loadingFinished_${key}`;
  const snackbarKeyCancel = `_cancel_${key}`;

  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  const getLoadingComponent = (text) => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <LinearProgress />
        <Box
          sx={{
            mt: '0.5rem',
          }}
        >
          {text}
        </Box>
      </Box>
    );
  };

  const handleExecute = async ({
    cancelText,
    cancelVariant = 'info',
    loadingText,
    loadingVariant = 'info',
    finishedText,
    finishedVariant = 'info',
    func,
    cancelFunc,
    onError,
  }) => {
    if (loadingText) {
      const LoadingComponent = getLoadingComponent(loadingText);

      enqueueSnackbar(LoadingComponent, {
        action: func && cancelFunc && cancelText && (
          <CancelButton
            onClick={async () => {
              cancelFunc();
              closeSnackbar(key);
              enqueueSnackbar(cancelText, {
                key: snackbarKeyCancel,
                variant: cancelVariant,
              });
            }}
          />
        ),
        key: snackbarKeyLoading,
        persist: true,
        variant: loadingVariant,
      });
    }

    try {
      const result = func && (await func());

      if (finishedText) {
        enqueueSnackbar(finishedText, {
          key: snackbarKeyLoadingFinished,
          preventDuplicate: true,
          variant: finishedVariant,
        });
      }

      return result;
    } catch (error) {
      if (onError) {
        onError(error, enqueueSnackbar);
      }
    } finally {
      closeSnackbar(snackbarKeyLoading);
    }
  };

  return handleExecute;
};

useLoadingSnackbar.propTypes = {
  key: PropTypes.string,
};

export default useLoadingSnackbar;
